<template>
  <div>
  <!--   <b-loading v-model="isLoading"> </b-loading> -->
    <client-nav></client-nav>
    <div class="section container">
      <div class="columns">
        <user-nav></user-nav>
        <div class="column">
              <h1 class="title is-2 has-text-primary has-text-centered">
              Ubezpieczenia
            </h1>
            <div v-if="hasCars">
              <div class="is-flex is-flex-wrap-wrap">
                <div
                  class="m-1"
                  v-for="(car, index) in cars"
                  :key="`car-${index}`"
                >
                  <b-button
                    :type="carSelectionArray[index] ? 'is-primary' : ''"
                    @click="selectCar(index, car.id)"
                    rounded
                  >
                    {{ index + 1 }}.
                    {{ car.car_details.serie.model.brand.name }}
                    {{ car.car_details.serie.model.name }}
                  </b-button>
                </div>
              </div>
            </div>
            <hr />
            <div class="cols level is-flex-direction-row-reverse">
                <div class="level-right">
                    <b-button
                    @click="addInsurenceModal = true"
                     class="is-primary" rounded>Dodaj ubezpieczenie</b-button>
                </div>
            </div>
            <div class="insurences-list" v-if="insurences && insurences.length > 0">
                <!--TABELA-->
                <b-table :data="insurences" >
            <b-table-column field="insurence_number" width="10" label="Nr polisy"  v-slot="props">
                {{ props.row.insurence_number }}
            </b-table-column>

            <b-table-column field="insurence_company" width="10" label="Firma ubezpieczeniowa"  v-slot="props">
                {{ props.row.insurence_company }}
            </b-table-column>

            <b-table-column field="insurence_start"  width="20" label="Rozpoczęcie umowy"  v-slot="props">
                {{ props.row.insurence_start }}
            </b-table-column>

            <b-table-column field="insurence_expire"  width="50" label="Koniec umowy"  v-slot="props">
                {{ props.row.insurence_expire }}
            </b-table-column>

            <b-table-column field="notification" width="10" label="Powiadomienie"  v-slot="props">
                {{ props.row.notification ? 'TAK' : 'NIE' }}
            </b-table-column>

            <b-table-column field="actions" label="Akcje" width="20" v-slot="props" >
                <div class="columns">
                    <div class="column">
                        <b-button @click="show(props.row)" class="is-primary" size="is-small">Podgląd</b-button>
                    </div>
                    <div class="column">
                        <b-button @click="edit(props.row)" class="" size="is-small">Edycja</b-button>
                    </div>
                    <div class="column">
                        <b-button @click="remove(props.row)" class="is-dark" size="is-small">Usuń</b-button>
                    </div>
                </div>
                
                
            </b-table-column>

                </b-table>
            </div>
            <div v-else>Brak ubezpieczeń dla tego pojazdu</div>
            <!--B-MODAL DODAJ-->
            <b-modal

                :can-cancel="['escape', 'x', 'outside']"
                v-model="addInsurenceModal"
            >
                <section>
                    <div class="modal-card"  style="width: auto"></div>
                    <header class="modal-card-head">
                        <div class="modal-card-title">Dodaj nowe ubezpieczenie samochodu</div>
                    </header>                    
                    <div class="modal-card-body has-background-white">
                        <div class="">
                            <form class="new-insurence-form ">
                                <b-field label="Nr ubezpieczenia" horizontal>
                                    <b-input v-model="newInsurence.insurence_number"></b-input>
                                </b-field>
                                <b-field label="Firma ubezpieczeniowa" horizontal>
                                    <b-input v-model="newInsurence.insurence_company"></b-input>
                                </b-field>
                                <b-field label="Start ubezpieczenia" horizontal>
                                   <b-datepicker
                                        v-model="newInsurence.insurence_start"
                                        :show-week-number="false"
                                        locale="locale"
                                        placeholder="Wybierz date ..."
                                        icon="calendar-today"
                                        icon-right="close-circle"
                                        icon-right-clickable
                                        trap-focus>
                                    </b-datepicker>
                                </b-field>
                                <b-field label="Koniec ubezpieczenia" horizontal>
                               <b-datepicker
                                        v-model="newInsurence.insurence_expire"
                                        :show-week-number="false"
                                        locale="locale"
                                        placeholder="Wybierz date ..."
                                        icon="calendar-today"
                                        icon-right="close-circle"
                                        icon-right-clickable
                                        trap-focus>
                                    </b-datepicker>
                                </b-field>
                                <b-field label="Cena ubezpieczenia" horizontal>
                                    <b-input type="number" v-model="newInsurence.price"></b-input>
                                </b-field>
                                <b-field label="Ubezpieczony samochód" horizontal>
                                    <b-select class="mt-2" expanded placeholder="Wybierz pojazd" v-model="newInsurence.my_car_id">
                                        <option v-for="(option, index) in cars"                
                                                    :value="option.id"
                                                    :key="option.id">
                                                   {{ index + 1 }} {{ option.car_details.serie.model.brand.name  }} {{ option.car_details.serie.model.name }}                
                                        </option>
                                    </b-select>
                                </b-field>
                                <b-field label="Notatka" horizontal>
                                    <b-input maxlength="200" type="textarea" v-model="newInsurence.note"></b-input>
                                </b-field>
                                <b-field label="Powiadomienie o zbliżającym się terminie?" horizontal>
                                    <b-switch class="mt-4" v-model="newInsurence.notification"></b-switch>
                                </b-field>                 
                                <div class="tile is-12">
                                    <b-button class="is-primary" type="submit" @click="add" expanded>Zapisz</b-button>
                                </div>                                                                                                                                                                                                               
                                
                            </form>
                            <div class="message mt-6" v-show="result">
                                <div class="success" v-if="!error">
                                    <b-message title="Operacja zakończona powodzeniem" type="is-primary" aria-close-label="Close message">
                                        Ubezpieczenie zostało dodane
                                    </b-message>
                                </div>
                                <div class="error" v-else>
                                    <b-message title="Operacja zakończona niepowodzeniem" type="is-danger" aria-close-label="Close message">
                                        Nie udało się dodać ubezpieczenia. {{ errorContent }}
                                    </b-message>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer class="modal-card-foot"></footer>
                </section>
            </b-modal>
            <!--B-MODAL EDYTUJ-->
            <b-modal v-model="editInsurenceModal">
                <section>
                    <div class="modal-card"></div>
                    <header class="modal-card-head">
                        <div class="modal-card-title">Edytuj ubezpieczenie</div>
                    </header>                    
                    <div class="modal-card-body">
                        <form class="new-insurence-form ">
                                <b-field label="Nr ubezpieczenia" horizontal>
                                    <b-input v-model="editInsurence.insurence_number"></b-input>
                                </b-field>
                                <b-field label="Firma ubezpieczeniowa" horizontal>
                                    <b-input v-model="editInsurence.insurence_company"></b-input>
                                </b-field>
                                <b-field label="Start ubezpieczenia" horizontal>
                                   <b-datepicker
                                        v-model="editInsurence.insurence_start"
                                        :show-week-number="false"
                                        locale="locale"
                                        placeholder="Wybierz date ..."
                                        icon="calendar-today"
                                        icon-right="close-circle"
                                        icon-right-clickable
                                        trap-focus>
                                    </b-datepicker>
                                </b-field>
                                <b-field label="Koniec ubezpieczenia" horizontal>
                               <b-datepicker
                                        v-model="editInsurence.insurence_expire"
                                        :show-week-number="false"
                                        locale="locale"
                                        placeholder="Wybierz date ..."
                                        icon="calendar-today"
                                        icon-right="close-circle"
                                        icon-right-clickable
                                        trap-focus>
                                    </b-datepicker>
                                </b-field>
                                <b-field label="Cena ubezpieczenia" horizontal>
                                    <b-input type="number" v-model="editInsurence.price"></b-input>
                                </b-field>
                                <b-field label="Ubezpieczony samochód" horizontal>
                                    <b-select disabled class="mt-2" expanded placeholder="Wybierz pojazd" v-model="editInsurence.my_car_id">
                                        <option v-for="(option, index) in cars"                
                                                    :value="option.id"
                                                    :key="option.id">
                                                   {{ index + 1 }} {{ option.car_details.serie.model.brand.name  }} {{ option.car_details.serie.model.name }}                
                                        </option>
                                    </b-select>
                                </b-field>
                                <b-field label="Notatka" horizontal>
                                    <b-input maxlength="200" type="textarea" v-model="newInsurence.note"></b-input>
                                </b-field>
                                <b-field label="Powiadomienie o zbliżającym się terminie?" horizontal>
                                    <b-switch class="mt-4" v-model="newInsurence.notification"></b-switch>
                                </b-field>  
                        </form>
                    </div>
                    <footer class="modal-card-foot">
                        <b-button @click="saveEdit" class="is-primary">Zapisz</b-button>
                        <b-button @click="editInsurenceModal=false" class="is-dark">Anuluj</b-button>
                    </footer>
                </section>
            </b-modal>
            <!--B-MODAL SZCZEGÓŁY-->
            <b-modal v-model="detailsInsurenceModal">
                <section>
                    <div class="modal-card"></div>
                    <header class="modal-card-head">
                        <div class="modal-card-title">Ubezpieczenie - szczegóły</div>
                    </header>                    
                    <div class="modal-card-body">
                        <div class="columns">
                            <div class="column">Nr polisy</div>
                            <div class="column">{{detailsInsurence.insurence_number}}</div>
                        </div>
                        <div class="columns">
                            <div class="column">Termin rozpoczęcia umowy</div>
                            <div class="column">{{detailsInsurence.insurence_start}}</div>
                        </div>
                        <div class="columns">
                            <div class="column">Termin wygaśnięcia umowy</div>
                            <div class="column">{{detailsInsurence.insurence_expire}}</div>
                        </div>
                        <div class="columns">
                            <div class="column">Firma ubezpieczeniowa</div>
                            <div class="column">{{detailsInsurence.insurence_company}}</div>
                        </div>
                        <div class="columns">
                            <div class="column">Notatka</div>
                            <div class="column">{{detailsInsurence.note}}</div>
                        </div>
                        <div class="columns">
                            <div class="column">Powiadomienia</div>
                            <div class="column">{{detailsInsurence.notification ? 'TAK' : 'NIE'}}</div>
                        </div>
                        <div class="columns">
                            <div class="column">Cena</div>
                            <div class="column">{{detailsInsurence.price}} zł</div>
                        </div>
                    </div>
                    <footer class="modal-card-foot"></footer>
                </section>
            </b-modal>
                        <!--B-MODAL USUŃ-->
            <b-modal v-model="deleteInsurenceModal">
                <section>
                    <div class="modal-card"></div>
                    <header class="modal-card-head">
                        <div class="modal-card-title">Ubezpieczenie - Czy chcesz je usunąć?</div>
                    </header>                    
                    <div class="modal-card-body">
                        <div class="columns">
                            <div class="column">Nr polisy</div>
                            <div class="column">{{insurenceToDeletePolisa}}</div>
                        </div>
                    </div>
                    <footer class="modal-card-foot">
                        <b-button @click="confirmAndDelete" class="is-dark">Usuń</b-button>
                        <b-button @click="deleteInsurenceModal = false" class="is-primary">Anuluj</b-button>
                    </footer>
                </section>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import ClientNav from "@/components/ClientNav";
import UserNav from '../../components/common/UserNav.vue';
export default {
    name: 'Insurences',
  components: {
    "client-nav": ClientNav,
    "user-nav": UserNav,

  },

  data() {
      return {
          newInsurence: {
              insurence_start: null,
              insurence_expire: null,
              insurence_company: null,
              insurence_number: null,
              note: null,
              price: null,
              notification: false,
              my_car_id: null,
          },
          editInsurence: {},
          detailsInsurence: {},

          result: false,
          error: false,
          errorContent: 'aaa',

          addInsurenceModal: false,
          detailsInsurenceModal: false,
          editInsurenceModal: false,
          deleteInsurenceModal: false,
          insurenceToDelete: null,
          insurenceToDeletePolisa: null,

          carSelectionArray: [],
      }
  },

  created() {
  },

  methods: {
    add: function(){
        this.$store.dispatch("insurence/add", {
            ...this.newInsurence,
              insurence_start: this.onlyDate(this.newInsurence.insurence_start),
              insurence_expire: this.onlyDate(this.newInsurence.insurence_expire),            
            }).then((res) => {
                res
            //console.log(res)
        }).catch((err) => {
            this.error = true
            this.errorContent = err
        }).finally(() => this.result = true)
    },
    onlyDate: function(dt){
        return dt.toISOString().split('T')[0]
    },
    edit: function(row){
        this.editInsurence = {...row, insurence_start: new Date(row.insurence_start), insurence_expire: new Date(row.insurence_expire)}
        this.editInsurenceModal = true
    },
    saveEdit: function(){
        this.$store.dispatch("insurence/edit", {
            ...this.editInsurence,
              insurence_start: this.onlyDate(this.editInsurence.insurence_start),
              insurence_expire: this.onlyDate(this.editInsurence.insurence_expire),            
            }).then((res) => {
            if(res == true) this.editInsurenceModal = false
        }).catch((err) => {
            this.error = true
            this.errorContent = err
        }).finally(() => {
            this.result = true
            this.editInsurenceModal = false
        })
    },
    remove: function(row){
        this.deleteInsurenceModal = true
        this.insurenceToDelete = row
        this.insurenceToDeletePolisa = row.insurence_number
    },
    confirmAndDelete: function(){
        this.insurenceToDelete.user_id = this.$store.getters['auth/id']
        //console.log(this.insurenceToDelete)
        this.$store.dispatch("insurence/delete", this.insurenceToDelete).then((res) => {
            if(res == true) this.deleteInsurenceModal = false
        }).catch((err) => {
            this.error = true
            this.errorContent = err
        }).finally(() => {
            this.result = true
            this.editInsurenceModal = false
        })
    },
    show: function(row){
        this.detailsInsurenceModal = true
        this.detailsInsurence = row
    },

    selectCar(index) {
      this.carSelectionArray = this.carSelectionArray.map(() => false);
      this.carSelectionArray[index] = true;
      this.newInsurence.my_car_id = index
      this.$store.dispatch("insurence/getAll", {
          myCarId: this.cars[index].id,
          userId: this.$store.getters['auth/id']
      });
    },
  },

  computed: {
    insurences(){
        return this.$store.getters["insurence/getInsurences"];
    },
    insurence(){
        return this.$store.getters["insurence/getInsurence"];
    },

    cars() {
      return this.$store.getters["clientCars/cars"];
    },

    hasCars() {
      return this.cars && this.cars.length > 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.field-label {
     flex-grow: 3!important;
}
</style>